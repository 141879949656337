import Topbar from './Commponents/Topbar'
import Footer from './Commponents/Footer'
import './locales/i18n';
import CommonPage from './Pages/CommonPage'
import { Switch, Route, Redirect } from 'react-router-dom';

function App() {
	return (
		<div className="App">
			<Topbar />
			<Switch>
				<Route path="/" component={CommonPage} />
				<Redirect exact from="/services" to="/services/clound_computing" />
				<Redirect exact from="/" to="/home" />
				<Redirect to="/home" />
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
