import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import TopbarCss from './index.module.scss';
import { ItemData } from '../../Tools/RouterOptions';
// import { useTranslation } from 'react-i18next'
import logo from '../../Source/Image/logo.png'

const Topbar = () => {
    const itemList = () => {
        return ItemData.map((item) =>
            item.show && Array.isArray(item.sub) && item.sub.length > 0 && item.sub.some(someItem => someItem.show) ?
                <Fragment key={item.id}>
                    <button className={`mdui-btn mdui-rippred mdui-hidden-sm-down`} mdui-menu={`{target: '#${item.id}'}`}>{item.name}</button>
                    <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`} id={item.id}>
                        {
                            item.sub.map(subItem =>
                                subItem.show && Array.isArray(subItem.sub) && subItem.sub.length > 0 && subItem.sub.some(someItem => someItem.show) ?
                                    <li key={subItem.id} className="mdui-menu-item">
                                        <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-red ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={subItem.name}>{subItem.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                        <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`}>
                                            {
                                                subItem.sub.map(subToSubItem =>
                                                    subToSubItem.show && Array.isArray(subToSubItem.sub) && subToSubItem.sub.length > 0 && subToSubItem.sub.some(someItem => someItem.show) ?
                                                        <li key={subToSubItem.id} className="mdui-menu-item">
                                                            <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-red ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={subToSubItem.name}>{subToSubItem.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                                            {itemList2(subToSubItem)}
                                                        </li>
                                                        :
                                                        <li key={subToSubItem.id} className={`mdui-menu-item`}>
                                                            <NavLink to={subToSubItem.slug} key={subToSubItem.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-red mdui-hidden-sm-down`}>{subToSubItem.name}</NavLink>
                                                        </li>
                                                )
                                            }
                                        </ul>
                                    </li>
                                    :
                                    <li key={subItem.id} className={`mdui-menu-item`}>
                                        <NavLink to={subItem.slug} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-red mdui-hidden-sm-down`}>{subItem.name}</NavLink>
                                    </li>
                            )
                        }
                    </ul>
                </Fragment>
                :
                <NavLink to={item.slug} key={item.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-red mdui-hidden-sm-down`}>{item.name}</NavLink>
        )
    }

    const itemList2 = (subItem) => {
        return <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`}>
            {
                Array.isArray(subItem.sub) && subItem.sub.length > 0 && subItem.sub.some(someItem => someItem.show) ?
                    subItem.sub.map(item =>
                        item.show && item.sub.length > 0 && item.sub.some(someItem => someItem.show) ?
                            <li key={item.id} className="mdui-menu-item">
                                <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-red ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={item.name}>{item.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                {itemList2(subItem.sub)}
                            </li>
                            :
                            <li key={item.id} className={`mdui-menu-item`}>
                                <NavLink to={item.slug} key={item.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-red mdui-hidden-sm-down`}>{item.name}</NavLink>
                            </li>
                    )
                    :
                    <li key={subItem.id} className={`mdui-menu-item`}>
                        <NavLink to={subItem.slug} key={subItem.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-red mdui-hidden-sm-down`}>{subItem.name}</NavLink>
                    </li>
            }
        </ul>
    }

    // const { t } = useTranslation()
    // const changeLanguage = (language_code) => {
    //     return () => {
    //         localStorage.setItem('i18nextLng', language_code)
    //         window.location.reload()
    //     }
    // }

    return (
        <>
            <div className={`mdui-appbar`}>
                <div className='mdui-container'>
                    <div className="mdui-toolbar">
                        <img src={logo} alt="" height='65%' />
                        <a href="/home" className={`mdui-typo-title ${TopbarCss.title}`}>SMARTIS TECHNOLOGY</a>
                        <div className="mdui-toolbar-spacer"></div>
                        {itemList()}{/* PC端菜单列表 */}
                        {/* 语言切换 */}
                        {/* <button className={`mdui-btn mdui-ripple mdui-ripple-red`} mdui-menu={`{target: '#switch-language'}`}>
                            <i class="mdui-icon material-icons">&#xe894;</i>&nbsp;&nbsp;{t("NowLanguage")}
                        </button>
                        <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`} id='switch-language'>
                            {
                                LanguageList.map(item =>
                                    <li key={item.slug} className={`mdui-menu-item`}>
                                        <a href="#!" className="mdui-ripple" title={item.name} onClick={changeLanguage(item.slug)}>
                                            {item.name}
                                        </a>
                                    </li>
                                )
                            }
                        </ul> */}
                        <div className="mdui-toolbar-spacer mdui-hidden-md-up"></div>
                        <button className="mdui-btn mdui-btn-icon mdui-hidden-md-up" mdui-drawer="{target: '#left-drawer'}">
                            <i className="mdui-icon material-icons">menu</i>
                        </button>
                    </div>
                </div>
            </div>
            <Sidebar itemList={ItemData} />
        </>
    );
}

export default Topbar;
