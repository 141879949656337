import React from 'react'
import Banner2Css from './index.module.scss'

export default function Banner2(props) {
    const { bg, title, summary_1, summary_2 } = props
    return (
        <div className={`mdui-valign ${Banner2Css.Banner2}`} style={{ backgroundImage: `url(${bg})` }}>
            <div className='mdui-center'>
                <div className={`mdui-text-center ${Banner2Css.Banner2_title}`}>{title}</div>
                <div className={`mdui-text-center ${Banner2Css.Banner2_detail}`}>{summary_1}</div>
                <div className={`mdui-text-center ${Banner2Css.Banner2_detail}`}>{summary_2}</div>
            </div>
        </div>
    )
}
