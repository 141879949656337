import React from 'react'
import { nanoid } from 'nanoid'
import Btn1 from '../Btn1'
import ShowBusinessCss from './index.module.scss'

export default function ShowBusiness(props) {
    const { business, slug } = props
    return (
        <div className={`mdui-container-fluid ${ShowBusinessCss.businessBlock}`}>
            <div className='mdui-row'>
                <div className='mdui-center mdui-hidden-md-up'>
                    <div className={`mdui-text-center ${ShowBusinessCss.businessBlock_summary_title}`}>Servicers</div>
                    <div className={`mdui-valign`}>
                        <div className={`mdui-center ${ShowBusinessCss.businessBlock_summary_line}`}></div>
                    </div>
                </div>
                {
                    business.map(
                        item => <div key={nanoid()} className={`mdui-col-xs-6 mdui-col-md-3 mdui-valign ${ShowBusinessCss.businessBlock_item}`} style={{ backgroundImage: `url(${item.bg})` }}>
                            <div className='mdui-center'>
                                <div className={`mdui-text-center ${ShowBusinessCss.businessBlock_title}`}>{item.title}</div>
                                <div className={`mdui-valign`}>
                                    <div className={`mdui-center ${ShowBusinessCss.businessBlock_line}`}></div>
                                </div>
                                <div className={`mdui-text-center ${ShowBusinessCss.businessBlock_detail}`}>{item.summary}</div>
                                <div className={`mdui-valign ${ShowBusinessCss.businessBlock_btn}`}>
                                    <div className={`mdui-center`}>
                                        <Btn1 btnType="button" btnAttr="min" text="more" slug={item.slug} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
