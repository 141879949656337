import React from 'react'
import { nanoid } from 'nanoid'
import CardTabCss from './index.module.scss'
import Btn1 from '../Btn1'

export default function CardTab(props) {

    const { CardTabData, slug } = props

    return (
        <div className={`mdui-container ${CardTabCss.itBlock}`}>
            <div className='mdui-row'>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-text-center ${CardTabCss.itBlock_title}`}>Other Servicers</div>
                    <div className={`mdui-valign`}>
                        <div className={`mdui-center ${CardTabCss.itBlock_line}`}></div>
                    </div>
                </div>
                {
                    CardTabData.map(
                        item => <div key={nanoid()} className={`mdui-col-xs-12 mdui-col-md-4 ${CardTabCss.itBlock_card}`}>
                            <div className={`mdui-valign ${CardTabCss.itBlock_card_block}`} style={{ backgroundImage: `url(${item.bg})` }}>
                                <div className={`mdui-center ${CardTabCss.itBlock_card_summary}`}>
                                    <div className={`mdui-text-center ${CardTabCss.itBlock_card_title}`}>{item.title}</div>
                                    <div className={`mdui-text-center ${CardTabCss.itBlock_card_btn}`}>
                                        <Btn1 btnType="button" btnAttr="min" text="more" slug={item.slug} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
