import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from "react-hook-form";
import Btn1 from '../Btn1'
import FeedbackCss from './index.module.scss'

export default function Feedback() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [dialogStatus, setDialogStatus] = useState({ display: false, title: "", detail: "" })
    const onSubmit = data => {
        const now_t = new Date().getMinutes() * 1
        const old_t = localStorage.getItem("tous")
        if (old_t && now_t - old_t * 1 < 10) {
            setDialogStatus({ display: true, title: "Prompt information", detail: "You have submitted consulting information, please do not submit it frequently in a short time, thank you!" })
            setTimeout(() => {
                setDialogStatus({ display: false, title: "Prompt information", detail: "You have submitted consulting information, please do not submit it frequently in a short time, thank you!" })
            }, 3000)
        } else {

            axios.post(`api/server/sendemail/`, data)
                .then((res) => {
                    setDialogStatus({ display: true, title: "Prompt information", detail: "Thank you for your letter! We will contact you as soon as possible." })
                    localStorage.setItem("tous", now_t)
                    setTimeout(() => {
                        setDialogStatus({ display: false, title: "Prompt information", detail: "You have submitted consulting information, please do not submit it frequently in a short time, thank you!" })
                    }, 3000)
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const entryMsg = () => {
        setDialogStatus({ display: false, title: "''", detail: "" })
    }

    return (
        <div className={`mdui-container ${FeedbackCss.containerDiv}`}>
            <div className='mdui-row'>
                <form className='mdui-col-xs-12' onSubmit={handleSubmit(onSubmit)}>
                    <div className={`mdui-col-md-4 ${FeedbackCss.containerBlock}`}>
                        <div className={`${FeedbackCss.containerBlock_title}`}><strong>Name<span>*</span>:</strong></div>
                        <input className={`${FeedbackCss.containerBlock_input}`} placeholder={"Please enter your name"} {...register("Name", { required: true })} />
                    </div>
                    <div className={`mdui-col-md-4 ${FeedbackCss.containerBlock}`}>
                        <div className={`${FeedbackCss.containerBlock_title}`}><strong>E-mail<span>*</span>:</strong></div>
                        <input className={`${FeedbackCss.containerBlock_input}`} placeholder={"Please input the E-mail"} {...register("email", { required: true })} />
                    </div>
                    <div className={`mdui-col-md-4 ${FeedbackCss.containerBlock}`}>
                        <div className={`${FeedbackCss.containerBlock_title}`}><strong>Company<span>*</span>:</strong></div>
                        <input className={`${FeedbackCss.containerBlock_input}`} placeholder={"Please enter company name"} {...register("Company", { required: true })} />
                    </div>
                    <div className={`mdui-col-md-12 ${FeedbackCss.containerBlock}`}>
                        <div className={`${FeedbackCss.containerBlock_title}`}><strong>MessageContents<span>*</span>:</strong></div>
                        <textarea className={`${FeedbackCss.containerBlock_input} ${FeedbackCss.containerBlock_textarea}`} rows='10' placeholder={"Describe your company's needs or the problem you are looking to solve"} {...register("MessageContents", { required: true })} />
                    </div>
                    <div className='mdui-col-md-12 mdui-valign'>
                        <div className='mdui-center'>
                            {/* <Btn1 btnType="submit" text="Click consult" /> */}
                            <button type='submit' className={`mdui-btn mdui-ripper ${FeedbackCss.custom_botton}`}>Click consult</button>
                        </div>
                    </div>
                </form>
                {/* 对话框 */}
                {
                    dialogStatus.display === true &&
                    <>
                        <div className="mdui-overlay mdui-overlay-show" style={{ zIndex: "5100" }}></div>
                        <div className="mdui-dialog mdui-dialog-open" id="dialog" style={{ display: 'block', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                            <div className="mdui-dialog-title">{dialogStatus.title}</div>
                            <div className="mdui-dialog-content">{dialogStatus.detail}</div>
                            <div className="mdui-dialog-actions">
                                <button className="mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down bw_theme_blue bw_theme_blue_btn" onClick={entryMsg}>Entry</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
