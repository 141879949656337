import React from 'react'
import ThemeBannerCss from './index.module.scss'
import resell_image from '../../Source/Image/resell_image.png'

export default function ThemeBanner() {
    return (
        <div className={`mdui-container ${ThemeBannerCss.itBlock}`}>
            <div className='mdui-row'>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-text-center ${ThemeBannerCss.itBlock_title}`}>Resell&value-added service</div>
                    <div className={`mdui-valign`}>
                        <div className={`mdui-center ${ThemeBannerCss.itBlock_line}`}></div>
                    </div>
                </div>
                <div className={`mdui-col-xs-12 mdui-text-center ${ThemeBannerCss.itBlock_summary}`}>
                    <p>Cloud/IT  infrastructure</p>
                    <p>design&implement</p>
                </div>
                <div className={`mdui-col-xs-12 mdui-text-center ${ThemeBannerCss.itBlock_cover}`}>
                    <img src={resell_image} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}
