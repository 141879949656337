export const ItemData = [
    {
        id: 1,
        slug: "/home",
        name: "home",
        template: "commonTemplate",
        show: true,
        crumbs: [
            {
                name: "home",
                slug: "/home"
            }
        ],
        sub: []
    },
    {
        id: 2,
        slug: "/services",
        name: "services",
        template: "commonTemplate",
        show: true,
        crumbs: [
            {
                name: "home",
                slug: "/home"
            },
            {
                name: "services",
                slug: "/services"
            }
        ],
        sub: [
            {
                id: 3,
                slug: "/services/clound_computing",
                name: "clound computing",
                template: "commonTemplate",
                show: true,
                crumbs: [
                    {
                        name: "home",
                        slug: "/home"
                    },
                    {
                        name: "services",
                        slug: "/services/clound_computing"
                    },
                    {
                        name: "clound computing",
                        slug: "/services/clound_computing"
                    }
                ],
                sub: []
            },
            {
                id: 4,
                slug: "/services/it_consultation",
                name: "IT consultation",
                template: "commonTemplate",
                show: true,
                crumbs: [
                    {
                        name: "home",
                        slug: "/home"
                    },
                    {
                        name: "services",
                        slug: "/services/clound_computing"
                    },
                    {
                        name: "IT consultation",
                        slug: "/services/it_consultation"
                    }
                ],
                sub: []
            },
            {
                id: 5,
                slug: "/services/digital_process_automation",
                name: "digital process automation",
                template: "commonTemplate",
                show: true,
                crumbs: [
                    {
                        name: "home",
                        slug: "/home"
                    },
                    {
                        name: "services",
                        slug: "/services/clound_computing"
                    },
                    {
                        name: "digital process automation",
                        slug: "/services/digital_process_automation"
                    }
                ],
                sub: []
            },
            {
                id: 6,
                slug: "/services/resell&value_added_service",
                name: "resell&value added service",
                template: "commonTemplate",
                show: true,
                crumbs: [
                    {
                        name: "home",
                        slug: "/home"
                    },
                    {
                        name: "services",
                        slug: "/services/clound_computing"
                    },
                    {
                        name: "resell&value added service",
                        slug: "/services/resell&value_added_service"
                    }
                ],
                sub: []
            },
        ]
    },
    {
        id: 7,
        slug: "/contact",
        name: "contact",
        template: "commonTemplate",
        show: true,
        crumbs: [
            {
                name: "home",
                slug: "/home"
            },
            {
                name: "contact",
                slug: "/contact"
            }
        ],
        sub: []
    }
]

export const LanguageList = [
    {
        name: '简体中文(CN)',
        slug: 'zh-CN',
    },
    {
        name: 'English(US)',
        slug: 'en-US'
    },
    {
        name: '中文繁体(HK)',
        slug: 'zh-HK',
    },
]