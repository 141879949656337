import React from 'react'
import FooterCss from './index.module.scss'
import beian from '../../Source/Image/beian.png'

export default function Footer() {
    return (
        <div className={`mdui-valign ${FooterCss.footer}`}>
            <div className={`mdui-center`}>SMARTIS TECHNOLOGY INTERNATIONAL LIMITED | <img className={FooterCss.footer_beian} src={beian} alt='' />Internet Standard Publish Number 61011602000395号</div>
        </div>
    )
}
