import React from 'react'
import { nanoid } from 'nanoid'
import ImageTextCss from './index.module.scss'
import Btn1 from '../Btn1'

export default function ImageText(props) {
    const { title, map, details, slug } = props

    return (
        <div className={`mdui-container ${ImageTextCss.itBlock}`}>
            <div className='mdui-row'>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-text-center ${ImageTextCss.itBlock_title}`}>{title}</div>
                    <div className={`mdui-valign`}>
                        <div className={`mdui-center ${ImageTextCss.itBlock_line}`}></div>
                    </div>
                </div>
                {/* <div className='mdui-col-xs-12 mdui-col-md-6'> */}
                <div className='mdui-col-xs-12'>
                    <div className={ImageTextCss.itBlock_img_block}>
                        <img src={map} alt="" width="100%" />
                    </div>
                </div>
                {/* <div className={`mdui-col-xs-12 mdui-col-md-6 ${ImageTextCss.itBlock_item_block}`}> */}
                <div className={`mdui-col-xs-12 ${ImageTextCss.itBlock_item_block}`}>
                    <ul className="mdui-list">
                        {
                            details.map(
                                item =>
                                    <li key={nanoid()} className="mdui-list-item mdui-ripple">
                                        <i className="mdui-list-item-icon mdui-icon material-icons">{item[0]}</i>
                                        <div className={`mdui-list-item-content ${ImageTextCss.itBlock_details}`}>
                                            {
                                                item[0] === "mail" ? <a className='mdui-typo' href={`mailto:${item[2]}`}>{item[1]}{item[2]}</a> : item[1]
                                            }
                                        </div>
                                    </li>
                            )
                        }
                    </ul>
                    {/* <div style={{ margin: "0 75px", textAlign:"center" }}>
                        <Btn1 btnType="button" btnAttr="min" text="more" slug={slug} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
