import React, { Fragment } from 'react'
import { nanoid } from 'nanoid';
import ImageText2Css from './index.module.scss'

export default function ImageText2(props) {
    const { title, cloud } = props
    return (
        <div className={`mdui-container ${ImageText2Css.itBlock}`}>
            <div className='mdui-row'>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-text-center ${ImageText2Css.itBlock_title}`}>{title}</div>
                    <div className={`mdui-valign`}>
                        <div className={`mdui-center ${ImageText2Css.itBlock_line}`}></div>
                    </div>
                </div>
                {
                    cloud.map(item =>
                        item.id % 2 === 0 ?
                            <Fragment key={nanoid()}>
                                <div className={`mdui-col-xs-12 mdui-col-md-5 ${ImageText2Css.itBlock_l}`}>
                                    <img className={ImageText2Css.itBlock_cover} src={item.img} alt="" />
                                </div>
                                <div className={`mdui-col-xs-12 mdui-col-md-7 ${ImageText2Css.itBlock_details}`}>
                                    <div className='mdui-center'>
                                        <div className={ImageText2Css.itBlock_details_title}>
                                            {item.title}
                                        </div>
                                        <div className={ImageText2Css.itBlock_details_summary}>
                                            {item.details}
                                        </div>
                                    </div>
                                </div>
                            </Fragment> :
                            <Fragment key={nanoid()}>
                                <div className={`mdui-col-md-7 mdui-hidden-sm-down ${ImageText2Css.itBlock_details}`}>
                                    <div className='mdui-center'>
                                        <div className={ImageText2Css.itBlock_details_title}>
                                            {item.title}
                                        </div>
                                        <div className={ImageText2Css.itBlock_details_summary}>
                                            {item.details}
                                        </div>
                                    </div>
                                </div>
                                <div className={`mdui-col-md-5 mdui-hidden-sm-down ${ImageText2Css.itBlock_l}`}>
                                    <img className={ImageText2Css.itBlock_cover} src={item.img} alt="" />
                                </div>
                                <div className={`mdui-col-md-5 mdui-hidden-md-up ${ImageText2Css.itBlock_l}`}>
                                    <img className={ImageText2Css.itBlock_cover} src={item.img} alt="" />
                                </div>
                                <div className={`mdui-col-md-7 mdui-hidden-md-up ${ImageText2Css.itBlock_details}`}>
                                    <div className='mdui-center'>
                                        <div className={ImageText2Css.itBlock_details_title}>
                                            {item.title}
                                        </div>
                                        <div className={ImageText2Css.itBlock_details_summary}>
                                            {item.details}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                    )
                }
            </div>
        </div >
    )
}
