import React from 'react'
import { nanoid } from 'nanoid'
import { useLocation } from 'react-router-dom';
import Banner from '../../Commponents/Banner'
import SummaryBlock from '../../Commponents/SummaryBlock'
import ShowBusiness from '../../Commponents/ShowBusiness'
import ImageText from '../../Commponents/ImageText'
import Line from '../../Commponents/Line'
import Feedback from '../../Commponents/Feedback'
import Banner2 from '../../Commponents/Banner2'
import ImageText2 from '../../Commponents/ImageText2'
import CardTab from '../../Commponents/CardTab'
import ThemeBanner from '../../Commponents/ThemeBanner'
import {
    homeData,
    cloundComputing,
    itConsultation,
    digitalProcessAutomation,
    resellValueAddedService,
    contact
} from '../../Tools/DemoData';

export default function Home() {
    const { pathname } = useLocation()

    let state = ''

    state =
        pathname === "/" || pathname === "/home" ? homeData :
            pathname === "/services/clound_computing" ? cloundComputing :
                pathname === "/services/it_consultation" ? itConsultation :
                    pathname === "/services/digital_process_automation" ? digitalProcessAutomation :
                        pathname === "/services/resell&value_added_service" ? resellValueAddedService :
                            pathname === "/contact" && contact

    return (
        <div>
            {
                state && state.commponents.map(
                    item =>
                        item.commponent_name === "banner" ? <Banner key={nanoid()} bg={item.bg} title={item.title} summary={item.summary} /> :
                            item.commponent_name === "summary_block" ? <SummaryBlock key={nanoid()} title={item.title} details_1={item.details_1} details_2={item.details_2} slug={item.slug} /> :
                                item.commponent_name === "show_business" ? <ShowBusiness key={nanoid()} business={item.business} slug={item.slug} /> :
                                    item.commponent_name === "image_text" ? <ImageText key={nanoid()} title={item.title} map={item.map} details={item.details} slug={item.slug} /> :
                                        item.commponent_name === "line" ? <Line key={nanoid()} /> :
                                            item.commponent_name === "feedback" ? <Feedback key={nanoid()} /> :
                                                item.commponent_name === "banner2" ? <Banner2 key={nanoid()} title={item.title} bg={item.bg} summary_1={item.summary_1} summary_2={item.summary_2} /> :
                                                    item.commponent_name === "image_text2" ? <ImageText2 key={nanoid()} title={item.title} cloud={item.cloud} /> :
                                                        item.commponent_name === "cardtab" ? <CardTab key={nanoid()} CardTabData={item.CardTabData} slug={item.slug} /> :
                                                            item.commponent_name === "theme_banner" && <ThemeBanner key={nanoid()} />
                )
            }
        </div>
    )
}
