import React from 'react'
import { useHistory } from 'react-router';
import Btn1Css from './index.module.scss'

export default function Btn1(props) {
  const history = useHistory()
  const jumpLinks = (slug) => {
    return () => {
      history.push(slug)
    }
  }
  const { btnType, text, btnAttr, slug } = props
  return (
    <div onClick={jumpLinks(slug)} type={btnType} className={`mdui-btn mdui-ripper ${Btn1Css.custom_botton}`} style={{ width: btnAttr === 'min' ? '48px' : '100%', padding: btnAttr === 'min' ? '0' : '0 60px' }}>{text}</div>
  )
}
