import React from 'react'
import BannerCss from './index.module.scss'

export default function Banner(props) {
    const { bg, title, summary } = props
    return (
        <div className={`mdui-valign ${BannerCss.banner}`} style={{ backgroundImage: `url(${bg})` }}>
            <div className={`mdui-center`}>
                <div className={`mdui-text-center ${BannerCss.banner_title}`}>{title}</div>
                <div className={`mdui-text-center ${BannerCss.banner_summary}`}>{summary}</div>
            </div>
        </div>
    )
}
