import banner_home from '../Source/Image/banner_home.png'
import banner_IT from '../Source/Image/banner_IT.png'
import service_1 from '../Source/Image/service_1.png'
import service_2 from '../Source/Image/service_2.png'
import service_3 from '../Source/Image/service_3.png'
import service_4 from '../Source/Image/service_4.png'
import map_img from '../Source/Image/map.png'
import banner_cloud from '../Source/Image/banner_cloud.png'
import cloud_Power_bi from '../Source/Image/cloud_Power_bi.png'
import cloud_Finebi from '../Source/Image/cloud-Finebi.png'
import Other_service_IT from '../Source/Image/Other_service_IT.png'
import other_service_resell from '../Source/Image/other_service_resell.png'
import Other_service_Dightal from '../Source/Image/Other_service_Dightal.png'
import IT_image from '../Source/Image/IT_image.png'
import banne_Digital from '../Source/Image/banne_Digital.png'
import Digital_image from '../Source/Image/Digital_image.png'
import banner_resell from '../Source/Image/banner_resell.png'
import banner_Contact from '../Source/Image/banner_Contact.png'

export const homeData = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner",
            bg: banner_home,
            title: "SMARTIS TECHNOLOGY ",
            summary: "Committed to helping Chinese SMEs enter the European market"
        },
        {
            commponent_name: "summary_block",
            title: "About Us",
            details_1: "SMARTIS TECHNOLOGY INTERNATIONAL LIMITED Committed to helping Chinese SMEs enter the European market.",
            details_2: "Whether you want to be a supplier to a European business or develop your own brand in Europe,Smartis technology can help you realize your dreams.",
            slug: "/contact"
        },
        {
            commponent_name: "show_business",
            business: [
                {
                    bg: service_1,
                    title: "Cloud Computing",
                    summary: "Cloud Computing",
                    slug: "/services/clound_computing"
                },
                {
                    bg: service_2,
                    title: "IT Consultation",
                    summary: "IT Consultation",
                    slug: "/services/it_consultation"
                },
                {
                    bg: service_3,
                    title: "Dightal Process Automation",
                    summary: "Dightal Process Automation",
                    slug: "/services/digital_process_automation"
                },
                {
                    bg: service_4,
                    title: "Resell&value-added service",
                    summary: "Resell&value-added service",
                    slug: "/services/resell&value_added_service"
                },

            ]
        },
        {
            commponent_name: "image_text",
            title: "Find Us",
            map: map_img,
            details: [
                // ["phone", "Tel： +44 （0）7517411870"],
                ["place", "Add：Flat J , 5/F ,Kwun Tong Industrial Centre , Phase II , 460-470 Kwun Tong Road , Kwun Tong , Kowloon , Hong Kong"],
                ["mail", "E-mail：", "sales@smartis-hk.com"],
            ],
            slug: "/contact"
        },
        {
            commponent_name: "line"
        },
        {
            commponent_name: "feedback"
        },
    ]
}

export const cloundComputing = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner2",
            bg: banner_cloud,
            title: "Cloud Computing",
            summary_1: "Microsoft Power bi/Microsoft partner",
            summary_2: "Fine BI"
        },
        {
            commponent_name: "image_text2",
            title: "Cloud Computing",
            cloud: [
                {
                    id: 0,
                    img: cloud_Power_bi,
                    title: 'Microsoft Power bi/Microsoft partner',
                    details: 'Provides products, solutions and development focusing on the full life cycle of business intelligence activities. With broad and deep experience in Microsoft data and reporting technology we can help you derive real value from your information using best practices and certified skilled professionals. We have helped thousands of customers of all sizes deploy a range of data and business intelligence projects, either outsourced or as part of a team.'
                },
                {
                    id: 1,
                    img: cloud_Finebi,
                    title: 'FineBI',
                    details: 'FineBI self-service analysis focuses on business requirements and provides free exploration and analysis through portable data processing and control.'
                }
            ]
        },
        {
            commponent_name: "cardtab",
            CardTabData: [
                {
                    bg: Other_service_IT,
                    title: "IT Consultation",
                    slug: "/services/it_consultation"
                },
                {
                    bg: other_service_resell,
                    title: "Dightal Process Automation",
                    slug: "/services/digital_process_automation"
                },
                {
                    bg: Other_service_Dightal,
                    title: "Resell&value-added service",
                    slug: "/services/resell&value_added_service"
                },
            ]
        }
    ]
}

export const itConsultation = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner2",
            bg: banner_IT,
            title: "IT Consultation",
            summary_1: "Help you solve any problems",
            summary_2: ""
        },
        {
            commponent_name: "image_text2",
            title: "IT Consultation",
            cloud: [
                {
                    id: 2,
                    img: IT_image,
                    title: 'IT digital solution design',
                    details: 'Our role in working with clients is to act as a knowledgeable and experienced guide in leading you to success. We do it every day by drawing on our field proven implementation experience, strong project methodology, deep advanced technology expertise and a cultural focus to serve our customers at the highest level possible.'
                }
            ]
        },
        {
            commponent_name: "cardtab",
            CardTabData: [
                {
                    bg: Other_service_IT,
                    title: "Cloud Computing",
                    slug: "/services/clound_computing"
                },
                {
                    bg: other_service_resell,
                    title: "Dightal Process Automation",
                    slug: "/services/digital_process_automation"
                },
                {
                    bg: Other_service_Dightal,
                    title: "Resell&value-added service",
                    slug: "/services/resell&value_added_service"
                },
            ]
        }
    ]
}

export const digitalProcessAutomation = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner2",
            bg: banne_Digital,
            title: "Digital Process Automation",
            summary_1: "",
            summary_2: ""
        },
        {
            commponent_name: "image_text2",
            title: "Digital Process Automation",
            cloud: [
                {
                    id: 2,
                    img: Digital_image,
                    title: '',
                    details: 'Integrated suite of advanced and intelligent RPA, IDP, and conversational Chatbot products can quickly and efficiently eliminate downtime and provide insights from day zero. Truly bringing AI to life, you will have better and faster access to relevant data. This empowers data-driven insights, leading to high-impact decisions to your business advantage. Quickly, efficiently, and effectively.'
                }
            ]
        },
        {
            commponent_name: "cardtab",
            CardTabData: [
                {
                    bg: Other_service_IT,
                    title: "Cloud Computing",
                    slug: "/services/clound_computing"
                },
                {
                    bg: other_service_resell,
                    title: "IT Consultation",
                    slug: "/services/it_consultation"
                },
                {
                    bg: Other_service_Dightal,
                    title: "Resell&value-added service",
                    slug: "/services/resell&value_added_service"
                },
            ]
        }
    ]
}

export const resellValueAddedService = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner2",
            bg: banner_resell,
            title: "Resell&value-added service ",
            summary_1: "Cloud/IT  infrastructure design&implement",
            summary_2: ""
        },
        {
            commponent_name: "theme_banner"
        },
        {
            commponent_name: "cardtab",
            CardTabData: [
                {
                    bg: Other_service_IT,
                    title: "Cloud Computing",
                    slug: "/services/clound_computing"
                },
                {
                    bg: other_service_resell,
                    title: "IT Consultation",
                    slug: "/services/it_consultation"
                },
                {
                    bg: Other_service_Dightal,
                    title: "Dightal Process Automation",
                    slug: "/services/digital_process_automation"
                },
            ]
        }
    ]
}

export const contact = {
    template: "common_template",
    commponents: [
        {
            commponent_name: "banner2",
            bg: banner_Contact,
            title: "Cloud Computing",
            summary_1: "Please contact us as soon as possible",
            summary_2: ""
        },
        {
            commponent_name: "image_text",
            title: "Find Us",
            map: map_img,
            details: [
                // ["phone", "Tel： +44 （0）7517411870"],
                ["place", "Add：Flat J , 5/F ,Kwun Tong Industrial Centre , Phase II , 460-470 Kwun Tong Road , Kwun Tong , Kowloon , Hong Kong"],
                ["mail", "E-mail：", "sales@smartis-hk.com"],
            ]
        },
        {
            commponent_name: "line"
        },
        {
            commponent_name: "feedback"
        }
    ]
}