import React from 'react'
import SummaryBlockCss from './index.module.scss'
import Btn1 from '../Btn1'

export default function SummaryBlock(props) {
    const { title, details_1, details_2, slug } = props
    return (
        <div className={`mdui-container`}>
            <div className='mdui-row'>
                <div className={`mdui-valign ${SummaryBlockCss.summary}`}>
                    <div className='mdui-center'>
                        <div className={`mdui-text-center ${SummaryBlockCss.summary_title}`}>{title}</div>
                        <div className={`mdui-valign`}>
                            <div className={`mdui-center ${SummaryBlockCss.summary_line}`}></div>
                        </div>
                        <div className={`mdui-text-center ${SummaryBlockCss.summary_detail}`}>
                            <p>{details_1}</p>
                            <p>{details_2}</p>
                        </div>
                        <div className={`mdui-valign`}>
                            <div className={`mdui-center`}>
                                <Btn1 btnType="button" btnAttr="min" text="more" slug={slug} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
